body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto;
  background-color: #27272c;
  color: #ececec;
  overflow-x: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

/* width */
::-webkit-scrollbar {
  width: 0.6rem;
  max-height: 69px; }

/* Track */
::-webkit-scrollbar-track {
  opacity: 0; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 7px; }

.AppContainer {
  height: calc(100vh - 75px);
  display: flex;
  flex-direction: column; }

.LinkText {
  color: #8c93dd;
  text-decoration: underline;
  cursor: pointer; }

.ErrorText {
  color: #ff9494; }

.Seperator {
  width: 100%;
  height: 2px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  background: #2f2f35; }

.OptionSeperator {
  font-size: 1pt;
  background-color: #2f2f35; }

input,
select {
  box-sizing: border-box;
  font-family: inherit;
  font-size: 18px;
  height: 40px;
  padding: 10px; }

select {
  padding: 0 10px; }

textarea {
  font-family: inherit;
  box-sizing: border-box;
  font-size: 18px;
  padding: 10px;
  resize: none; }

input,
textarea,
select {
  outline: none;
  color: #c0c0c0;
  border: 1px solid #18181b;
  border-radius: 3px;
  background-color: #27272c; }
  input:focus,
  textarea:focus,
  select:focus {
    box-shadow: 0 0 2px 2px #60659b; }
  input::placeholder,
  textarea::placeholder,
  select::placeholder {
    color: #7a7a7a;
    font-weight: 500; }

.Modal {
  position: relative;
  margin: auto;
  padding: 10px 30px;
  background: #1f1f23;
  border-radius: 4px;
  margin: 40px;
  outline: none; }
  @media (max-width: 640px) {
    .Modal {
      width: 100%;
      margin: 20px; } }
  .Modal__Overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20; }
  .Modal__Close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    opacity: 0.5;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 12px; }
    .Modal__Close:hover {
      opacity: 1; }
    .Modal__Close:before, .Modal__Close:after {
      position: absolute;
      height: 2rem;
      width: 3px;
      background-color: #c5c5c5;
      content: ' '; }
    .Modal__Close:before {
      transform: rotate(45deg); }
    .Modal__Close:after {
      transform: rotate(-45deg); }
  .Modal__Title {
    font-size: 30px;
    margin-bottom: 5px;
    margin-right: 25px; }
    @media (max-width: 768px) {
      .Modal__Title {
        font-size: 24px; } }

.PrimaryButton,
button {
  background-color: #60659b;
  color: #ececec;
  padding: 5px 10px;
  font-size: 18px;
  border-radius: 3px;
  font-weight: 400;
  text-shadow: 1px 1px 1px black;
  border: none;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer; }
  .PrimaryButton:disabled,
  button:disabled {
    background-color: #27272c;
    color: #9a9a9a; }
  .PrimaryButton--red,
  button--red {
    background-color: #ff6f6f; }

.Switch {
  position: relative;
  display: inline-block;
  min-width: 30px;
  width: 30px;
  height: 17px; }
  .Switch input {
    opacity: 0;
    width: 0;
    height: 0; }
    .Switch input:checked + .Switch__Slider {
      background-color: #60659b; }
    .Switch input:focus + .Switch__Slider {
      box-shadow: 0 0 1px #60659b; }
    .Switch input:checked + .Switch__Slider:before {
      transform: translateX(13px); }
  .Switch__Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e5e5e5;
    border-radius: 17px;
    transition: 0.4s; }
    .Switch__Slider:before {
      position: absolute;
      content: '';
      height: 13px;
      width: 13px;
      left: 2px;
      bottom: 2px;
      background-color: #27272c;
      border-radius: 50%;
      transition: 0.4s; }

.AddMedia__Modal {
  max-width: 1085px;
  padding-bottom: 45px; }
  .AddMedia__Modal input,
  .AddMedia__Modal select,
  .AddMedia__Modal textarea {
    width: 345px; }
  .AddMedia__Modal textarea {
    height: 100px; }
  .AddMedia__Modal .Seperator {
    margin-bottom: 20px; }
  @media (max-width: 768px) {
    .AddMedia__Modal {
      padding-bottom: 60px;
      width: fit-content; }
      .AddMedia__Modal input,
      .AddMedia__Modal select,
      .AddMedia__Modal textarea {
        max-width: 345px;
        width: 100%; } }
  @media (max-width: 640px) {
    .AddMedia__Modal {
      width: 100%;
      margin: 20px; }
      .AddMedia__Modal input,
      .AddMedia__Modal select,
      .AddMedia__Modal textarea {
        max-width: 100%;
        width: 100%; } }
  @media (max-width: 420px) {
    .AddMedia__Modal {
      width: 100%;
      margin: 15px; } }

.AddMedia__Content {
  display: flex;
  gap: 25px;
  margin: 25px; }
  @media (max-width: 1024px) {
    .AddMedia__Content {
      margin: 25px 0; } }
  @media (max-width: 768px) {
    .AddMedia__Content {
      flex-direction: column;
      margin: 25px 0; } }
  .AddMedia__Content--left {
    display: flex;
    flex-direction: column;
    gap: 15px; }
  .AddMedia__Content--right {
    display: flex;
    flex-direction: row-reverse;
    gap: 25px; }
    @media (max-width: 1024px) {
      .AddMedia__Content--right {
        flex-direction: column-reverse;
        justify-content: flex-start; } }

.AddMedia__TitleInput input:focus + .Suggestions {
  display: block; }

.AddMedia .Suggestions {
  position: absolute;
  box-sizing: border-box;
  display: none;
  color: black;
  width: 345px;
  max-height: 150px;
  border: 1px solid #c5c5c5;
  background-color: #e5e5e5;
  overflow: auto;
  z-index: 5; }
  .AddMedia .Suggestions:hover {
    display: block; }
  .AddMedia .Suggestions__Item {
    min-height: 30px;
    padding: 0 10px;
    line-height: 27px;
    border-bottom: 1px solid rgba(197, 197, 197, 0.1);
    cursor: pointer; }
    .AddMedia .Suggestions__Item:hover {
      background-color: #cecece; }
  .AddMedia .Suggestions__ItemName {
    font-size: 14px; }
    .AddMedia .Suggestions__ItemName span {
      color: #959595; }

.AddMedia .MediaInfo {
  max-width: 410px; }
  @media (max-width: 1024px) {
    .AddMedia .MediaInfo {
      max-width: 345px;
      width: 100%; } }
  @media (max-width: 640px) {
    .AddMedia .MediaInfo {
      max-width: 100%; } }
  .AddMedia .MediaInfo__Title {
    font-family: sans-serif;
    font-size: 28px;
    margin-left: -5px; }
  .AddMedia .MediaInfo__Year {
    color: #c0c0c0;
    font-size: 22px;
    margin-top: -10px; }
  .AddMedia .MediaInfo__Type {
    font-size: 18px; }
  .AddMedia .MediaInfo__Description {
    color: #c0c0c0;
    font-size: 18px;
    margin-bottom: 3px; }
  .AddMedia .MediaInfo .Seperator {
    margin: 8px 0; }

.AddMedia__Poster {
  width: 200px;
  height: 300px; }

.AddMedia__Button {
  position: absolute;
  bottom: 20px;
  right: 30px;
  height: 30px; }

.MediaDetails__Modal input,
.MediaDetails__Modal select,
.MediaDetails__Modal textarea {
  width: 345px; }

.MediaDetails__Modal textarea {
  height: 100px; }

@media (max-width: 768px) {
  .MediaDetails__Modal {
    max-width: 80%; } }

@media (max-width: 420px) {
  .MediaDetails__Modal {
    max-width: 70%; } }

.MediaDetails__Modal__Title {
  font-size: 32px;
  margin-right: 30px; }

.MediaDetails__Content {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 60px; }
  @media (max-width: 768px) {
    .MediaDetails__Content {
      flex-direction: column; } }

.MediaDetails__Poster {
  width: 200px;
  height: 300px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25); }

.MediaDetails__Details {
  max-width: 420px;
  word-wrap: break-word; }
  @media (max-width: 768px) {
    .MediaDetails__Details {
      max-width: 100%; }
      .MediaDetails__Details input,
      .MediaDetails__Details textarea {
        width: 100%; } }
  .MediaDetails__Details .Seperator {
    margin-top: 15px;
    margin-bottom: 25px;
    width: 90%; }
  .MediaDetails__Details p {
    font-size: 18px;
    font-weight: 500;
    margin: 5px 0; }
  .MediaDetails__Details span {
    font-size: 16px;
    font-weight: 400;
    color: #c0c0c0; }

.MediaDetails__Description {
  color: #c0c0c0; }

.MediaDetails__Buttons {
  position: absolute;
  display: flex;
  bottom: 20px;
  right: 30px; }
  .MediaDetails__Buttons button {
    margin-left: 15px; }

.ListSettings {
  margin-top: 15px; }
  .ListSettings__Name {
    display: flex;
    align-items: center;
    gap: 10px; }
    @media (max-width: 640px) {
      .ListSettings__Name {
        flex-direction: column;
        align-items: start; } }
  .ListSettings__NameInput {
    font-size: 18px;
    width: 230px; }
    @media (max-width: 640px) {
      .ListSettings__NameInput {
        width: 100%;
        max-width: 230px; } }
  .ListSettings__InviteLink {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px; }
    @media (max-width: 640px) {
      .ListSettings__InviteLink {
        flex-direction: column;
        align-items: start; } }
    .ListSettings__InviteLink .PrimaryButton {
      white-space: nowrap; }
    .ListSettings__InviteLink .InviteLink__Input {
      width: 310px;
      font-size: 14px; }
      @media (max-width: 640px) {
        .ListSettings__InviteLink .InviteLink__Input {
          width: 100%;
          max-width: 310px; } }
      @media (max-width: 420px) {
        .ListSettings__InviteLink .InviteLink__Input {
          padding: 5px; } }
  .ListSettings .ErrorText {
    margin-bottom: 5px; }
  .ListSettings__SharedUsers h3 {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    margin-bottom: 5px; }
    @media (max-width: 420px) {
      .ListSettings__SharedUsers h3 {
        font-size: 18px; } }
    .ListSettings__SharedUsers h3 span {
      font-size: 18px;
      margin-top: auto; }
      @media (max-width: 420px) {
        .ListSettings__SharedUsers h3 span {
          font-size: 14px; } }
  .ListSettings .SharedUser {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #2f2f35;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px; }
    .ListSettings .SharedUser__Username {
      margin: 0;
      margin-right: 5px;
      margin-left: 5px;
      word-break: break-all;
      white-space: normal; }
      @media (max-width: 420px) {
        .ListSettings .SharedUser__Username {
          font-size: 14px; } }
    .ListSettings .SharedUser__Remove {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1rem;
      height: 1rem;
      opacity: 0.5;
      cursor: pointer;
      margin-bottom: 1px; }
      .ListSettings .SharedUser__Remove:hover {
        opacity: 1; }
      .ListSettings .SharedUser__Remove:before, .ListSettings .SharedUser__Remove:after {
        position: absolute;
        height: 1rem;
        width: 3px;
        background-color: #c5c5c5;
        content: ' '; }
      .ListSettings .SharedUser__Remove:before {
        transform: rotate(45deg); }
      .ListSettings .SharedUser__Remove:after {
        transform: rotate(-45deg); }
    .ListSettings .SharedUser .Switch {
      margin-left: auto; }
  .ListSettings__ButtonsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem; }

.DeleteModal {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem; }
  .DeleteModal__Buttons {
    display: flex;
    gap: 2rem; }

.ConfirmModal {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem; }
  .ConfirmModal__Text {
    text-align: center; }
  .ConfirmModal__Buttons {
    display: flex;
    gap: 2rem; }

.MyLists__Sidebar {
  position: relative;
  min-width: 240px;
  height: calc(100vh - 75px);
  background-color: #1f1f23;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden; }
  @media (max-width: 768px) {
    .MyLists__Sidebar {
      position: absolute;
      left: -240px;
      transition: 0.25s; }
      .MyLists__Sidebar--active {
        left: 0; } }
  .MyLists__Sidebar:hover {
    overflow: auto; }

.Sidebar__ExpandButton {
  position: fixed;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  z-index: 5;
  left: 0px;
  width: 39px;
  height: 39px;
  margin-top: 3px;
  padding: 6px 7px;
  border-radius: 0 5px 5px 0;
  background-color: #1f1f23;
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.2);
  transition: 0.25s;
  cursor: pointer; }
  @media (max-width: 768px) {
    .Sidebar__ExpandButton {
      display: flex; } }
  .Sidebar__ExpandButton--active {
    left: 240px; }
  .Sidebar__ExpandButton .ExpandButton__Line {
    width: 100%;
    height: 3px;
    border-radius: 5px;
    background-color: #c5c5c5; }
  .Sidebar__ExpandButton:hover {
    box-shadow: 1px 1px 4px #60659b; }

.Sidebar__Top {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  background-color: #18181b;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25); }
  .Sidebar__Top h2 {
    font-size: 28px;
    font-weight: 500;
    margin: 0;
    margin-left: 25px;
    margin-right: 20px; }

.Sidebar__Item {
  display: flex;
  align-items: center;
  min-height: 45px;
  border-bottom: 1px solid #2f2f35;
  cursor: pointer; }
  .Sidebar__Item:hover, .Sidebar__Item--active {
    background-color: #2f2f35; }
    .Sidebar__Item:hover .Sidebar__Selector, .Sidebar__Item--active .Sidebar__Selector {
      fill-opacity: 0.25; }
  .Sidebar__Item .Seperator {
    width: 80%;
    margin-top: 7px; }

.Sidebar__ListName {
  display: flex;
  align-items: center;
  margin-left: 10px; }
  .Sidebar__ListName h3 {
    font-size: 22px;
    font-weight: 400;
    max-width: 180px;
    margin: 0;
    margin-left: 5px;
    word-wrap: break-word; }

.Sidebar__Selector {
  width: 15px;
  height: 15px;
  margin-top: 5px;
  fill: #000;
  fill-opacity: 0; }
  .Sidebar__Selector--active {
    fill-opacity: 0.5 !important; }

.NewList__Modal {
  width: 350px; }
  @media (max-width: 420px) {
    .NewList__Modal {
      padding: 10px; } }

.NewList__Content {
  margin-top: 15px;
  margin-bottom: 10px; }

.NewList__Heading {
  font-size: 18px;
  font-weight: 400; }

.NewList__Name {
  display: flex;
  align-items: center;
  gap: 7px;
  white-space: nowrap;
  margin-top: 7px; }

.NewList__Input {
  width: 100%;
  max-width: 300px; }

.NavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  max-height: 75px;
  min-height: 75px;
  padding: 0 25px;
  background-color: #0e0e10;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  z-index: 10; }
  .NavBar__MenuDrawer {
    position: absolute;
    display: none;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 10;
    top: 75px;
    left: 0;
    width: 100%;
    height: calc(100% - 75px);
    padding: 10px;
    background-color: #1f1f23; }
    .NavBar__MenuDrawer a {
      display: flex;
      align-items: center;
      text-decoration: none;
      height: 50px;
      padding-left: 10px;
      border-radius: 10px;
      color: #ececec;
      font-size: 18px;
      font-weight: 600; }
      .NavBar__MenuDrawer a:hover {
        background-color: #393939; }
    @media (max-width: 640px) {
      .NavBar__MenuDrawer--active {
        display: flex; } }
  .NavBar__BurgerButton {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 36px;
    height: 40px;
    cursor: pointer; }
    @media (max-width: 640px) {
      .NavBar__BurgerButton {
        display: flex; } }
    .NavBar__BurgerButton .BurgerButton__Line {
      width: 100%;
      height: 5px;
      border-radius: 5px;
      background-color: #c5c5c5; }
    .NavBar__BurgerButton--active .BurgerButton__Line {
      background-color: #60659b; }
    .NavBar__BurgerButton:hover .BurgerButton__Line {
      background-color: #757575; }

.NavBar__TitleContainer {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none; }

.NavBar__Logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #60659b; }

.NavBar__Title {
  margin: 0;
  font-size: 42px;
  font-weight: 400; }

.NavBar__Links {
  display: flex;
  justify-content: space-around;
  gap: 20px; }
  .NavBar__Links a {
    color: inherit;
    font-size: 20px;
    text-decoration: none; }
    .NavBar__Links a:hover {
      text-decoration: underline; }
  @media (max-width: 640px) {
    .NavBar__Links {
      display: none; } }

.LoginRegister {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  margin: auto; }
  .LoginRegister__Label {
    font-size: 24px;
    margin-bottom: 10px; }
  .LoginRegister__Form {
    display: flex;
    flex-direction: column; }
    .LoginRegister__Form input {
      margin: 5px 0; }
    .LoginRegister__Form button {
      margin-top: 15px; }
  .LoginRegister__ForgotPassword {
    margin-top: 15px;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: #9a9a9a; }

.MyLists {
  display: flex;
  height: 100%;
  min-height: 250px; }
  .MyLists__Content {
    width: 100%;
    padding: 15px;
    overflow: auto; }
    @media (max-width: 768px) {
      .MyLists__Content {
        padding: 15px;
        padding-top: 50px; } }
    @media (max-width: 420px) {
      .MyLists__Content {
        padding-top: 40px; } }
  .MyLists__Options {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin: 0 10px 10px; }
    @media (max-width: 640px) {
      .MyLists__Options {
        margin-top: 0; } }
    @media (max-width: 420px) {
      .MyLists__Options {
        flex-direction: column-reverse;
        gap: 15px;
        margin-top: 15px; } }
    .MyLists__Options--left {
      display: flex;
      justify-content: space-between;
      min-width: 150px; }
      @media (max-width: 420px) {
        .MyLists__Options--left {
          justify-content: start;
          gap: 10px; } }
    .MyLists__Options--right {
      display: flex;
      gap: 15px; }
      @media (max-width: 640px) {
        .MyLists__Options--right button {
          font-size: 14px;
          font-weight: 600; } }
      @media (max-width: 420px) {
        .MyLists__Options--right {
          flex-direction: row;
          justify-content: start; }
          .MyLists__Options--right button {
            width: 100%;
            font-size: 18px;
            font-weight: 400; } }
  .MyLists__FilterButton {
    color: #9c9c9c;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    align-self: flex-end; }
    .MyLists__FilterButton:hover {
      text-decoration: underline; }
    .MyLists__FilterButton--active {
      color: inherit;
      text-decoration: underline; }
  .MyLists__Media {
    min-height: calc(100% - 50px);
    box-sizing: border-box;
    padding: 0 55px 15px;
    background-color: #1f1f23; }
    @media (max-width: 640px) {
      .MyLists__Media {
        padding: 15px; } }
    @media (max-width: 420px) {
      .MyLists__Media {
        min-height: calc(100% - 100px); } }
    .MyLists__Media .Media__Category {
      padding: 20px 0; }
      @media (max-width: 640px) {
        .MyLists__Media .Media__Category {
          padding: 0;
          padding-bottom: 20px; } }
    .MyLists__Media .Media__Header {
      font-size: 36px;
      font-weight: 400;
      margin: 0 0 15px 5px; }
      @media (max-width: 640px) {
        .MyLists__Media .Media__Header {
          margin: 0; } }
    .MyLists__Media .Media__Container {
      display: flex;
      flex-wrap: wrap;
      gap: 15px; }
      @media (max-width: 1024px) {
        .MyLists__Media .Media__Container {
          display: grid;
          grid-template-columns: 33fr 33fr 33fr; } }
      @media (max-width: 640px) {
        .MyLists__Media .Media__Container {
          grid-template-columns: 50fr 50fr; } }
    .MyLists__Media .Media__Item {
      cursor: pointer;
      width: 175px; }
      @media (max-width: 1024px) {
        .MyLists__Media .Media__Item {
          width: 100%; } }
      .MyLists__Media .Media__Item .Item__Poster {
        width: 100%;
        aspect-ratio: 2 / 3;
        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25); }
      .MyLists__Media .Media__Item .Item__Title {
        font-size: 18px;
        font-weight: 400;
        margin: 0;
        margin-top: 3px;
        margin-left: 3px;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5); }

.InviteScreen {
  font-size: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
  margin: auto 15vw; }
  .InviteScreen__ValidContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem; }
  .InviteScreen__AcceptButton {
    font-size: 2rem; }
  .InviteScreen__ReturnButton {
    background-color: #393939; }

.AccountSettings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 297px;
  margin: auto; }
  .AccountSettings__Section input {
    margin-top: 5px;
    margin-right: 0.5rem; }
  .AccountSettings__ChangePassword {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px; }
  .AccountSettings__DeleteAccount {
    margin: 25px 0;
    height: 2.5rem; }
  .AccountSettings .ErrorText,
  .AccountSettings .StatusText {
    text-align: center; }
    @media (max-width: 420px) {
      .AccountSettings .ErrorText,
      .AccountSettings .StatusText {
        margin: 0; } }

.EmailVerification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto 15vw; }

.SendEmailVerification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto 15vw; }
  .SendEmailVerification input {
    margin-right: 10px; }
  .SendEmailVerification .ErrorText {
    margin-top: 10px; }

.PasswordReset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto 15vw; }
  .PasswordReset__PasswordForm {
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 10px; }
  .PasswordReset__FormInput {
    display: flex;
    flex-direction: column; }
  .PasswordReset #email {
    margin-right: 7px;
    margin-bottom: 7px; }
  .PasswordReset__BackToLogin {
    background-color: #2f2f35;
    margin-top: 2.5rem; }

.About {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto; }
  .About__Content {
    max-width: 700px;
    margin: 0 75px; }
    @media (max-width: 640px) {
      .About__Content {
        margin: 0 25px; } }
  .About__Paragraph {
    font-size: 18px;
    line-height: 24px; }
  .About__ContactHeader {
    font-size: 24px;
    margin-top: 50px; }
  .About__Email {
    color: #60659b;
    text-decoration: underline;
    cursor: pointer; }
